import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import Checkbox from '@mui/material/Checkbox';
import axios from "axios";
import { config, S3} from 'aws-sdk';
import { ServerConfig } from "../../connectors/Config";
import moment from "moment";
import { Buffer } from "buffer";
import { LoadingButton } from "@mui/lab";
import { useSnackBar } from "../../views/providers/consumers/useSnackBar";
import store, { setFieldState } from "../../views/store/formStore";
import FilterHelper from '../../views/controllers/FilterHelper';
import { useSelector } from "react-redux";
import GzipHelper from "../../views/controllers/GzipHelper";
import { useBuilder } from "../useBuilder";


const RemoteSelect = (props) => {
    const [choices, setChoices] = React.useState([]);
    const values = useSelector(state => state.fieldsData);
    const ref = useRef(null);

    const removeDuplicates = (array, key) => {
        const seen = new Set();
        return array.filter(item => {
          const value = item[key];
          if (seen.has(value)) {
            return false;
          } else {
            seen.add(value);
            return true;
          }
        });
      };
    const getOffers = async() => {
            const res = await fetch(props.endpoint.replace('{committente}', values['Committente'].toUpperCase()));
            let typeFilter = values['Tipologia Cliente'] === 'Business' ? 'BUSINESS' : 'RESIDENZIALI';
            let powerFilter = values['Tipologia fornitura'].toUpperCase();
            let _json = await GzipHelper.unzip(await res.json())
            console.log(_json);
            let json = _json.filter(x => x.type_contract === typeFilter)
                                            .filter(x => x.type === powerFilter);
            json = removeDuplicates(json, 'name');
            setChoices([...json]);

    }
    React.useEffect(() => {
        if(props.endpoint.includes('{committente}')){
            getOffers();
        }
       
        axios.get(props.endpoint).then(async (res) => {
            let result = await GzipHelper.unzip(res.data)

            console.log('helper function', props.filter?.helper[0]);

            if(FilterHelper[props.filter?.helper[0]]){
                console.log(result);
                result = result.filter(x => FilterHelper[props.filter.helper[0]](x.name) === true);
                console.log(result);
                console.log('RISULTATO FILTRO', FilterHelper[props.filter.helper[0]]('Fidasi'))
            }
            if(result.length > 0){
                setChoices([...result.filter(x => x['Visibile contratto'] === 'S' && x['status'] === 'S')]);
                const event = new Event('change', { bubbles: true });
                console.log('ciao evento ciao');
                window.setTimeout(() => {
                    ref.current?.dispatchEvent(event);
                    if(ref.current){
                        console.log('ref current ci sta');
                    }
                }, 1500)
            }
        })
    }, [])
    
    return (
        choices.length > 0 && <TextField ref={ref} id={'committente'} select fullWidth {...props} variant="outlined" 
        InputLabelProps={{
            sx: {
                color: "#444",
              }
        }}
        InputProps={{sx: {
            border: '1px solid #eee',
            outlineColor: 'wheat',
            color: '#000',
            fontWeight: 'bold'
        }}}
        inputProps={{style: {color: '#000'}}} 
        style={{color: '#000'}} 
        sx={{color: '#000', marginBottom: 1}}>
            {choices.map(x => 
                <MenuItem value={x.name}>{x.name}</MenuItem>)}
            
        </TextField>
    )
}

const CommercialActionSelect = (props) => {
    
    const values = useSelector(state => state.fieldsData);

    const committenti = {
        ILLUMIA: [
            "SWITCH"
        ],
        "ENI POLIZZE": [],
        "EDISON": [
            "SWITCH"
        ],
        "FASTWEB": [
            "NUOVA ATTIVAZIONE",
            "PORTABILITÀ"
        ],
        IREN: [
            "SWITCH",
            "SWITCH CON VOLTURA LUCE",
            "SUBENTRO"
        ],
        SORGENIA: [
            "SWITCH",
            "SWITCH CON VOLTURA",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
            "POSA CONTATORE"
        ],
        ACEA: [
            "SWITCH",
        ],
        LOOMEN: [
            "SWITCH",
            "SWITCH CON VOLTURA",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
            "POSA CONTATORE"
        ],
        UNION: [
            "SWITCH",
            "SWITCH CON VOLTURA LUCE",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
            "POSA CONTATORE"
        ],
        DUFERCO: [
            "SWITCH",
            "SWITCH CON VOLTURA LUCE",
            "SUBENTRO",
            "NUOVA ATTIVAZIONE",
            "POSA CONTATORE"
        ],
        "ENI POLIZZE": [
            "NO AZIONE COMMERCIALE"
        ],
        "ENEL COMPARATORE": [
            "SWITCH",
            "NUOVA ATTIVAZIONE",
            "SUBENTRO"
        ],
        "ENEL AGENZIA": [
            "SWITCH"
        ],
        "ENEL FIBRA AGENZIA": [
            "NUOVA ATTIVAZIONE",
            "PORTABILITÀ"
        ],
        "ENEL FIBRA COMPARATORE": [
            "NUOVA ATTIVAZIONE",
            "PORTABILITÀ"
        ],
        "ENI COMPARATORE": [
            "SWITCH"
        ],
        "ENI AGENZIA": [
            "SWITCH"
        ]
    };

    const { builder } = useBuilder();
    
    let group = committenti[values['Committente'].toUpperCase()] ? committenti[values['Committente'].toUpperCase()] : [
        "SWITCH",
        "SUBENTRO",
        "NUOVA ATTIVAZIONE",
    ];
    
    console.log(group);
    return (
        group && <TextField select fullWidth {...props} variant="outlined" 
        InputLabelProps={{
            sx: {
                color: "#444",
              }
        }}
        InputProps={{sx: {
            border: '1px solid #eee',
            outlineColor: 'wheat',
            color: '#000',
            fontWeight: 'bold',
        }}}
        inputProps={{style: {color: '#000'}}} 
        style={{color: '#000'}} 
        label="Azione Commerciale"
        onChange={(e) => {
            store.dispatch(setFieldState({name: "Azione Commerciale", value: e.target.value}));
            try{
                builder(`contracts.subschema.azionicommerciali.${e.target.value.toLowerCase().replaceAll(' ', '_').replaceAll('.', '_')}`);
            }catch(ex){
            }
        }}
        
        sx={{color: '#000', marginBottom: 1, marginTop: 2}}>
            {group.map(x => <MenuItem value={x}>{x}</MenuItem>)}
            {/* {choices.map(x => 
                <MenuItem value={x.name}>{x.name}</MenuItem>)} */}
            
        </TextField>
    )
}

const FormInput = (props) => {
    const values = useSelector(state => state.fieldsData);

    if(props.exceptions && props.exceptions[0]?.show === 'only' && props.exceptions[0]?.committente === values['Committente']?.toUpperCase()){
        return (
            <TextField fullWidth {...props} variant="outlined" 
                        InputLabelProps={{
                            sx: {
                                color: "#444",
                                }
                        }}
                        InputProps={{sx: {
                            border: '1px solid #eee',
                            outlineColor: 'wheat'
                        }}}
                        inputProps={{style: {color: '#000'}}} 
                        style={{color: '#000'}} 
                        sx={{color: '#000', marginBottom: 1}} 
                        ref={props.ref}
                        />);
    }else if(props.exceptions && props.exceptions[0]?.show === 'only' && props.exceptions[0]?.committente !== values['Committente']?.toUpperCase()){
        return null;
    }
    return (
        <TextField fullWidth {...props} variant="outlined" 
                    InputLabelProps={{
                        sx: {
                            color: "#444",
                            }
                    }}
                    InputProps={{sx: {
                        border: '1px solid #eee',
                        outlineColor: 'wheat'
                    }}}
                    inputProps={{style: {color: '#000'}}} 
                    style={{color: '#000'}} 
                    sx={{color: '#000', marginBottom: 1}} 
                    ref={props.ref}
                    />);
    
}

const FormSelect = (props) => (<FormControl fullWidth>
    <TextField select fullWidth {...props} variant="outlined" 
                            InputLabelProps={{
                                sx: {
                                    color: "#444",
                                  }
                            }}
                            InputProps={{sx: {
                                border: '1px solid #eee',
                                outlineColor: 'wheat',
                                color: '#000',
                                fontWeight: 'bold'
                            }}}
                            inputProps={{style: {color: '#000'}}} 
                            style={{color: '#000'}} 
                            sx={{color: '#000', marginBottom: 1}}>
        {props.choices && props.choices.map((choice, idx) => {
            if(props.labels){
                return (
                    <MenuItem value={choice}>{props.labels[idx]}</MenuItem>
                )
            }else {
                return (
                    <MenuItem value={choice}>{choice}</MenuItem>
                ) 
            }
        })}
    </TextField>
    {/* <Select label={props.label} >
       
    </Select> */}
</FormControl>)


const FormAutoComplete = (props) => {
    return (
    <Autocomplete
        disablePortal
        id={`autocomplete-${props.label}`}
        options={[...new Set(props.choices.map((choice) => ({label: choice[props.extractor]})))]}
        renderInput={(params) => <div ref={params.InputProps.ref}>
            <TextField fullWidth {...params.inputProps} 
                label={props.label}
                variant="outlined" 
                InputLabelProps={{
                    sx: {
                        color: "#444",
                    }
                }}
                InputProps={{sx: {
                    border: '1px solid #eee',
                    outlineColor: 'wheat',
                    color: '#000',
                    fontWeight: 'bold'
                }}}
                inputProps={{style: {color: '#000'}}} 
                style={{color: '#000'}} 
                sx={{color: '#000'}}
            />
        </div>} 
    />
        
)}

const FormDatePicker = (props) => {
    const [open, setOpen] = useState(false);
    const inputRef = useRef();
    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            label={props.label}
            inputFormat="DD/MM/YYYY"
            onChange={(e) => {setOpen(false);}}
            open={open}
            renderInput={(params) => (<TextField 
            {...params.inputProps} 
            ref={inputRef} 
            onFocus={() => setOpen(true)} 
            onBlur={() => setOpen(false)} 
            fullWidth {...params}
            variant="outlined" 
            InputLabelProps={{
                sx: {
                    color: "#444",
                }
            }}
            InputProps={{sx: {
                border: '1px solid #eee',
                outlineColor: 'wheat',
                color: '#000',
                fontWeight: 'bold'
            }}}
            inputProps={{style: {color: '#000'}}} 
            style={{color: '#000'}} 
            sx={{color: '#000'}}
                />)} 
        />
    </LocalizationProvider>
    )
}

const fileConverterBase64 = (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            res(reader.result)
        }
        reader.onerror = (err) => {
            rej(err)
        }
    })
}
const FileUploaderS3 = (props) => {
    const { addAlert } = useSnackBar();

    config.update({
        Region: ServerConfig.AWS.s3Bucket.Region, 
        credentials: {
            accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
            secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
        }});
    const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
        accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
        secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
    }});
    const fileRef = useRef();

    const [loading, setLoading] = useState(false);

    return (<>
    <LoadingButton loading={loading} onClick={() => fileRef.current?.click()} variant="contained" color="secondary" sx={{mb:2, mt: 2}}>{loading ? 'Caricamento...' :props.label}</LoadingButton>
      <input type="file" ref={fileRef} hidden onChange={async (e) => {
        setLoading(true);
        try{
            const fileBase64 = await fileConverterBase64(e.target.files[0]);
            const fileParticles = fileBase64.split(',')[0];
            const fileName = `${moment().valueOf()}.contract-file.${fileParticles.split(';')[0].split('/')[1]}`
            const params = {
                Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                Key: fileName,
                Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                ContentType: e.target.files[0].type,
                ACL: 'public-read'
            };
            
            const res = await s3.putObject(params).promise();
            
            store.dispatch(setFieldState({name: props.fieldName, value: `https://crm-storage-files-no-worm.s3.eu-west-1.amazonaws.com/${fileName}`}))
            addAlert({
                message: 'Caricamento file completato',
                severity: 'success'
            })
            setLoading(false);
        }catch(ex){

            addAlert({
                message: 'Errore server',
                severity: 'error'
            })
        }
      }}/>
    
    </>
    )
}
const FormSwitcher = (props) => {
    const values = useSelector(state => state.fieldsData);
    let exceptions = props.exceptions?.filter(x => x.committente?.toUpperCase() === values['Committente']?.toUpperCase());
    exceptions = exceptions?.length > 0 ? exceptions[0].only : null;
    return(<FormControl key={props.fieldName} >
        <FormLabel id="demo-radio-buttons-group-label" sx={{color: '#222'}}>{props.label}</FormLabel>
            <RadioGroup
            name="radio-buttons-group"
            onChange={props.onChange}
            row
            >
                
                {exceptions ? props.subforms?.filter(x => exceptions.includes(x.label)).map(form => (
                    <FormControlLabel sx={{
                        color: '#222'
                    }} value={form.label} control={<Radio formtocall={form.form} />} label={form.label} ciao="true" />
                )) : props.subforms?.map(form => (
                    <FormControlLabel sx={{
                        color: '#222'
                    }} value={form.label} control={<Radio formtocall={form.form} />} label={form.label} ciao="true" />
                ))}
            </RadioGroup>
        </FormControl>)
    
}

{/**
    PARTI DA QUI PER ARRIVARE AI SINGOLI COMPONENTI    
*/}
const switchElement = (props, value, setValue, ref) => {
    switch(props.type){
        case "string": return <FormInput  key={props.fieldName} {...props} ref={ref} />
        case "enum": return <FormSelect key={props.fieldName}  {...props} />
        case "autocomplete": return <FormAutoComplete key={props.fieldName}  {...props} />
        case "date": return <FormDatePicker key={props.fieldName}  {...props} onChange={(e) => console.log(e)}/>
        case "number": return <FormInput key={props.fieldName}  {...props} type="number" />
        case "form_switch": return (<FormSwitcher {...props} />)
        case "file-upload-s3": return (<FileUploaderS3 {...props} />)
        case "remote_select": return (<RemoteSelect endpoint={props.endpoint} {...props}/>)
        case "checkbox": return (  <FormControlLabel control={<Checkbox />} {...props} sx={{color: '#000'}}/>)
        case "azione_commerciale": return (<CommercialActionSelect />)
        default: return null
    }
}


const FormHandler = (props) => {
    const [value, setValue] = useState("");

    return switchElement(props, value, setValue);
}
export { FormHandler}